
#markdown-post-id {
 background-color: #25272C;
    padding: 25px;
    width: 100%;
    max-width: 730px;
    padding-bottom: 30px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

#markdown-post-id h1 {
 color: #FC0021;
 font-size: 36px;
 font-weight: bold;
 margin-bottom: 10px;
}

#markdown-post-id h2 {
 color: #fff;
 font-size: 24px;
 font-weight: 600;
 margin-bottom: 10px;
 margin-top: 10px;
}

#markdown-post-id h3 {
 color: #fff;
 font-size: 20px;
 font-weight: 500;
 margin-bottom: 5px;
 margin-top: 5px;
}

#markdown-post-id h2:first-of-type {
 color: #929292;
 font-size: 24px;
 font-weight: 600;
 margin-bottom: 10px;
 margin-top: 10px;
}

#markdown-post-id p {
 color: #fff;
 font-size: 18px;
 margin-bottom: 5px;
}

#markdown-post-id hr {
    border: 1px solid #FC0021;
    width: 60%;
    margin: 15px auto; 
}

#markdown-post-id img {
    border-radius: 10px;
    margin: 25px auto; 
}

#markdown-post-id a {
 color: #FC0021;
 font-weight: bold;
}

#markdown-post-id ul {
 margin-top: 10px;
 margin-bottom: 10px;
}

#markdown-post-id ul li::before {
     content: '•';
    color: #fff; /* Custom color */
    font-size: 20px; /* Custom size */
    margin-right: 5px; /* Space between the marker and the text */
}

#markdown-post-id ol {
 margin-top: 10px;
 margin-bottom: 10px;
 list-style-type: decimal;
 margin-left: 10px;
 font-weight: bold;
}

