body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #0B0B0B;
}

::-webkit-scrollbar-thumb {
  background: #25272C;
  border-radius: 25px;
}

:root {
    --wt-text-on-background-color: white !important;
}

.white-scrollbar::-webkit-scrollbar-track {
  background: #25272C;
}

.white-scrollbar::-webkit-scrollbar-thumb {
  background: white;
}

.white-scrollbar::-webkit-scrollbar-thumb:hover {
  background: white;
}

.white-scrollbar::-webkit-scrollbar {
  width: 8px;
}


@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.animate-fade-in {
  animation: fade-in 0.2s ease-in-out;
}

@keyframes borderAnimation {
  0% {
    border-color: #FC0021;
  }
  50% {
    border-color: #a70319;
  }
  100% {
    border-color: #FC0021;
  }
}

.border-animate {
  animation: borderAnimation 2s infinite;
}

.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateX(0%);
  opacity: 1;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

.slide-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
