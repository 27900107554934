#single-post-container {
  width: 100% !important;
  max-width: 730px !important;
}

#single-post-container table {
  width: 100% !important;
  max-width: 730px !important;
  padding: 0 !important;
  background-color: white !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

#single-post-container a {
  text-decoration: inherit;
  text-decoration-color: #fc0021 !important;
}

#single-post-container div {
  color: #fff !important;
    background-color: #25272C !important;  
      border-color: #25272C !important;   
}

#single-post-container {


}

#single-post-container h1 {
  color: #fc0021 !important;
}

#single-post-container h2 {
  color: #fc0021 !important;
}

#single-post-container h6 {
  color: #fc0021 !important;
}
#single-post-container h5 {
  color: #fc0021 !important;
}

#single-post-container a {
  color: #fc0021 !important;
}

#single-post-container i {
  color: #fff !important;
}

#single-post-container span {
  color: #fff !important;
}

.rendered-post {
  background-color: #25272c !important;
  padding: 25px;
  width: 100% !important;
  max-width: 730px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.block {
  display: flex !important;
}
.space-x-4 > :not(:last-child) {
  margin-right: 1rem;
}

.space-x-4 > * {
  background-color: #fc0021;
  border-radius: 50%;
}

.bg-wt-background {
  background-color: #25272C !important;   
}
